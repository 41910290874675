import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Про мотомайстерню Joltmed
			</title>
			<meta name={"description"} content={"Кожен мотоцикл має свою історію, давайте напишемо наступну главу"} />
			<meta property={"og:title"} content={"Про нас | Про мотомайстерню Joltmed"} />
			<meta property={"og:description"} content={"Кожен мотоцикл має свою історію, давайте напишемо наступну главу"} />
			<meta property={"og:image"} content={"https://joltmed.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://joltmed.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://joltmed.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://joltmed.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://joltmed.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://joltmed.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://joltmed.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--green"
			color="--dark"
			padding="80px 0"
			sm-padding="40px 0"
			background="--color-light"
		>
			<Text
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				мотомайстеря Joltmed
			</Text>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
			Про нас 
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Кожен мотоцикл має свою історію, давайте напишемо наступну главу
			</Text>
			<Box min-width="100px" min-height="100px">
				<Button
					type="link"
					font="--headline3"
					text-decoration-line="initial"
					color="--dark"
					border-radius="8px"
					border-width="1px"
					border-style="solid"
					border-color="--color-dark"
					background="rgba(0, 119, 204, 0)"
					href="/contacts"
				>
					Будемо на зв'язку
				</Button>
			</Box>
		</Section>
		<Section color="--dark" background="--color-light">
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
					Наша мета
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				У Joltmed ми віримо, що мотоцикл - це не просто машина, а розповідь на двох колесах, кожна поїздка на якій - нова сторінка. Наша майстерня - це редакторський стіл, де відшліфовуються історії та вдосконалюються характеристики.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
					Майстерність у поєднанні з інноваціями
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Наш підхід поєднує майстерність олдскульних механіків з точністю сучасних технологій. Цей сплав гарантує, що кожен велосипед, який покидає нашу майстерню, не тільки працює краще, але й несе в собі частинку душі, яку можуть прищепити лише справжні ентузіасти.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
				Наша історія
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Хоча ми не зациклюємося на минулому, саме багата історія класичних мотоциклів та еволюція їзди на них надихає нас на створення наших послуг. Joltmed народився з бажання створити простір, орієнтований на громаду, де сходяться знання, майстерність і гострі відчуття від їзди на мотоциклі.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
				Прагнення до досконалості
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Ми займаємося не лише заміною мастила та налаштуванням - ми прагнемо довести ваш мотоцикл до ідеального стану. Кожен інструмент, кожна техніка і кожна рука, яка працює над вашим мотоциклом, керується невпинним прагненням до досконалості.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
				Заради любові до велосипедів
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Ми поділяємо нерозривний зв'язок з велоспільнотою, який виходить за рамки бізнесу. Ми прагнемо зберегти спадщину та гарантувати, що кожна наступна поїздка буде такою ж захоплюючою, як і перша.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7" background="--color-light">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://joltmed.com/img/2.jpg"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://joltmed.com/img/3.jpg"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://joltmed.com/img/4.jpg"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://joltmed.com/img/5.jpg"
							position="absolute"
							width="100%"
							left={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://joltmed.com/img/6.jpg"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					Розпочніть свою подорож - відчуйте мистецтво мотоциклетної досконалості в Joltmed
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});